import React from "react";
import { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Routeassignments } from "../../Data/Data";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { CaretRightOutlined } from "@ant-design/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchactiveServicedashboard } from "../../redux/Slices/getActiveServiceRoute";
import { DatePicker } from "antd";
import socket from "../../Socket";

function Routetabs({ data, id }) {
  const dispatch = useDispatch();

  const [Dates, setDates] = useState([]);

  const date1 = new Date();
  const [date, setDate] = useState(date1.toISOString().split("T")[0]);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // Store current date

  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem("date") || currentDate
  ); // Get date from localStorage or use current date

  const [NewDate, setNewDate] = useState();

  const [technician_id, settechnician_id] = useState(null);

  // const GetDates = () => {
  //   var start = new Date();
  //   var end = new Date(start);

  //   end.setDate(start.getDate() + 3);
  //   let Dates = [];

  //   var loop = new Date(start);

  //   for (let i = 0; i < 4; i++) {
  //     if (i == 0) {
  //       var newDates = loop.setDate(loop.getDate() + i);
  //       loop = new Date(newDates);
  //       Dates.push(moment(loop).format("YYYY-MM-DD"));
  //     } else {
  //       var newDates = loop.setDate(loop.getDate() - 1);
  //       loop = new Date(newDates);
  //       Dates.push(moment(loop).format("YYYY-MM-DD"));
  //     }
  //   }

  //   setDates(Dates.reverse());
  //   return Dates;
  // };

  const handleDateChange = (date) => {
    // Create a moment object for the given date
    const momentDate = moment(date);

    // Get the current time in UTC
    const currentTimeUTC = moment.utc().format("HH:mm:ss");

    // Combine the current date with the current time in UTC
    const utcDate = moment(
      momentDate.format("YYYY-MM-DD") + "T" + currentTimeUTC
    ).toISOString();

    return utcDate;
  };

  const GetDates = () => {
    let Dates = [];
    const start = new Date();
    let loop = new Date(start);

    // Get the first day of the week (Sunday)
    // For Monday change  loop.setDate(start.getDate() - (start.getDay() - 1));
    loop.setDate(start.getDate() - start.getDay());

    for (let i = 0; i < 7; i++) {
      Dates.push(moment(loop).format("YYYY-MM-DD"));
      loop.setDate(loop.getDate() + 1);
    }

    // If you want the dates in ascending order, remove the reverse()
    setDates(Dates);
    return Dates;
  };

  useEffect(() => {
    GetDates();
  }, []);

  const getCurrentDate = (date) => {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because month index starts from 0
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T00:00:00Z`;

    return formattedDate;
  };
  const onChange = (date1, dateString) => {
    const room = `${id}/${data?.SocketData?.givenDate}`;
    console.log(room);
    const leaveRoom = () => {
      socket.emit("LeaveRoute", room, (data) => {
        if (data.status === "error") {
          console.error("Error leaving room:", data.message);
        } else {
          console.log("Successfully left room:", room);
        }
      });
    };
    leaveRoom();

    setDate(getCurrentDate(dateString));

    const date = handleDateChange(dateString);
    dispatch(fetchactiveServicedashboard({ date, technician_id }));
  };

  const handleDates = async (date2) => {
    const date = handleDateChange(date2);
    localStorage.setItem("date", date2);
    await setNewDate(date2);
    dispatch(fetchactiveServicedashboard({ date, technician_id }));
  };
  useEffect(() => {
    localStorage.setItem("date", selectedDate);
  }, [selectedDate, data]);
  let savedate = localStorage.getItem("date");

  return (
    <Fragment>
      <Row className="cslocation">
        <Col sm={8}>
          <Nav variant="pills" className="flex-column">
            <Swiper
              // slidesPerView={3}
              spaceBetween={10}
              loop={false}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 4,
                },
                1440: {
                  slidesPerView: 3,
                },
                1550: {
                  slidesPerView: 5,
                },
              }}
              className="mySwiper dateSwiperr"
            >
              {Dates?.map((item) => {
                return (
                  <SwiperSlide>
                    <Nav.Item className={item == savedate ? "aaa" : "bbb"}>
                      <Nav.Link
                        eventKey={item}
                        onClick={() => handleDates(item)}
                      >
                        {moment(item).format("Do MMM")}
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Nav>
        </Col>
        <div className="col-sm-4 DatePicker-1">
          <DatePicker onChange={onChange} />
        </div>
      </Row>
    </Fragment>
  );
}

export default Routetabs;
