import React, { useEffect, useRef, useState } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { RiUserLocationFill } from "react-icons/ri";
import { ImLocation2 } from "react-icons/im";
import { Modal } from "antd";
import { renderToString } from "react-dom/server";

function GoogleMap({ MapData }) {
  console.log(MapData, "MapData");
  const mapRef = useRef(null);
  const directionsService = new google.maps.DirectionsService();
  const [currentDirectionsRenderers, setCurrentDirectionsRenderers] = useState(
    []
  );
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  // Clear previous directions and markers
  const clearPreviousDirections = () => {
    currentDirectionsRenderers.forEach((renderer) => {
      renderer.setMap(null);
    });
    setCurrentDirectionsRenderers([]);
    currentMarkers.forEach((marker) => marker.setMap(null));
    setCurrentMarkers([]);
  };

  // Function to render directions between two points
  const renderDirections = (
    origin,
    destination,
    color_code,
    technicianName,
    customerName,
    travelMode,
    address
  ) => {
    const request = {
      origin,
      destination,
      travelMode,
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK" && result.routes.length > 0) {
        const directionsRenderer = new google.maps.DirectionsRenderer({
          map: mapRef?.current?.map,
          directions: result,
          suppressMarkers: true,
        });

        const polylineOptions = {
          strokeColor: color_code,
          strokeOpacity: 0.8,
          strokeWeight: 4,
        };
        directionsRenderer.setOptions({ polylineOptions });

        setCurrentDirectionsRenderers((prev) => [...prev, directionsRenderer]);
      } else {
        console.error("Error fetching directions:", status);
      }
    });

    const circle = new google.maps.Circle({
      strokeColor: color_code,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color_code,
      fillOpacity: 1,
      map: mapRef.current.map,
      center: destination,
      radius: 2,
    });

    const destinationMarker = new google.maps.Marker({
      position: destination,
      map: mapRef.current.map,
      label: {
        text: technicianName,
        color: "#000",
        fontWeight: "600",
        fontSize: "10px",
        fontFamily: "Poppins",
      },
      icon: {
        url: `data:image/svg+xml,${encodeURIComponent(
          renderToString(<RiUserLocationFill color={color_code} size={24} />)
        )}`,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 30),
      },
    });

    const originMarker = new google.maps.Marker({
      position: origin,
      map: mapRef.current.map,
      label: {
        text: `${customerName}`,
        color: "#000",
        fontWeight: "600",
        fontSize: "15px",
        fontFamily: "Poppins",
        className: "marker-label-origin1",
      },
      icon: {
        url: `data:image/svg+xml,${encodeURIComponent(
          renderToString(<ImLocation2 color={color_code} size={24} />)
        )}`,
        scaledSize: new google.maps.Size(30, 30),
      },
    });

    originMarker.addListener("click", () => {
      setModalContent({ address, firstName: customerName });
      setIsModalVisible(true);
    });

    destinationMarker.addListener("click", () => {
      setTimeout(() => {
        mapRef.current.map.setCenter(destination);
        mapRef.current.map.setZoom(10);
      }, 1000);
    });

    setCurrentMarkers((prev) => [
      ...prev,
      circle,
      destinationMarker,
      originMarker,
    ]);
  };

  // Render all data markers and directions
  const renderAllData = () => {
    const bounds = new google.maps.LatLngBounds();
    const dataArray = Array.isArray(MapData?.RouteAssignmentTechnician)
      ? MapData.RouteAssignmentTechnician
      : [];

    dataArray.forEach((technician) => {
      const serviceLocation = new google.maps.LatLng(
        MapData?.latitude,
        MapData?.longitude
      );
      const technicianLocation = new google.maps.LatLng(
        technician?.RouteAssignmentWaterBody?.servicelat,
        technician?.RouteAssignmentWaterBody?.servicelong
      );

      bounds.extend(serviceLocation);
      bounds.extend(technicianLocation);

      renderDirections(
        technicianLocation,
        serviceLocation,
        MapData.color_code,
        MapData.first_name,
        technician?.RouteAssignmentWaterBody?.position,
        "DRIVING",
        technician?.RouteAssignmentWaterBody?.service_address
      );
    });

    mapRef.current.map.fitBounds(bounds);
  };

  useEffect(() => {
    if (mapRef.current && MapData) {
      clearPreviousDirections(); // Clear previous markers and directions
      renderAllData(); // Re-render the new data
    }
  }, [MapData]); // Re-render whenever MapData changes

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="googlemap_frame">
      <Map
        google={google}
        ref={mapRef}
        className="mapRadius"
        style={{ width: "100%", height: "80%" }}
        zoom={10}
      />

      <Modal
        title="Details"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p className="modal-map-1">
          <span>{`Route Stop: ${modalContent.firstName}`}</span>
        </p>
        <p className="modal-map-1">
          <span>{`Address: ${modalContent.address}`}</span>
        </p>
      </Modal>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
