import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import {
  UploadModelData,
  resetData,
} from "../../redux/postReducer/postUploadModel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchgetCustomers } from "../../redux/Slices/getCustomer";
import Loader from "../NoDataComponent/Loader";
import Papa from "papaparse";
import axios from "axios";

const DeleteModal = ({ data1 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fileContent, setFileContent] = useState(null);
  const [state, setState] = useState({
    fileName: "",
    fileContent: "",
    parsedContent: [],
    errorContent: null,
  });

  const { data, success, error, loading } = useSelector(
    (state) => state.UploadModel
  );
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    setFileContent(file);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setState({
        fileName: file.name,
        fileContent: reader.result,
        parsedContent: [],
        errorContent: null,
      });

      if (
        file.type === "application/vnd.ms-excel" ||
        file.name.endsWith(".csv")
      ) {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            setState((prevState) => ({
              ...prevState,
              parsedContent: result.data,
            }));
          },
        });
      }
    };

    reader.onerror = () => {
      message.error("Failed to read the file!");
    };
  };

  const downloadErrorFile = (errorContent) => {
    if (errorContent) {
      const json = JSON.stringify(errorContent, null, 2);
      const blob = new Blob([json], {
        type: "application/json;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "error_log.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //   function downloadPDF(url) {
  //     const a = document.createElement('a');
  //     a.style.display = 'none';
  //     a.href = url;
  //     a.download = 'download.pdf';

  //     document.body.appendChild(a);
  //     a.click();

  //     document.body.removeChild(a);
  // }

  // const pdfLink = 'https://junzhiassets.s3.us-east-1.amazonaws.com/Legacy/d319f8795c7fc65e6186e85d9e803a8ec2d13032fa233bcdc0d8562caf30863f';

  // Call the function to initiate the download

  const handleSubmit1 = async (data) => {
    const formData = new FormData();
    formData.append("file", fileContent);

    const res = await dispatch(UploadModelData({ formData }));
    console.log(res.payload);

    if (res.payload.success) {
      const blob = new Blob([res.payload], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "download.pdf";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      const errorPdfLink = res.payload.pdflink;

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = errorPdfLink;
      a.download = "error.pdf";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    }
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", fileContent);

    const res = await dispatch(UploadModelData({ formData }));
    console.log(res, "============");
    const pdfLink = res?.payload?.PdfLink;

    if (pdfLink) {
      window.open(pdfLink, "_blank");
    }
  };

  // const handleSubmit = async () => {
  //   const formData = new FormData();
  //   formData.append("file", fileContent);

  //   const response = await dispatch(UploadModelData({ formData }));

  //   if (response?.payload?.Error) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       errorContent: response.payload.Error
  //     }));
  //     downloadErrorFile(response.payload.Error);
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       errorContent: null
  //     }));
  //   }
  // };

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch(fetchgetCustomers({ currentPage }));
      dispatch(resetData());
      data1.handleClose();
    }
    if (error) {
      toast.error(error);
      dispatch(resetData());
    }
  }, [error, success, dispatch, data1, currentPage]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="import_css">
          <input
            className="import_css_input"
            type="file"
            accept=".csv, application/json, .xlsx"
            onChange={onSelectFile}
          />
          {state.fileName && (
            <div className="file-preview">
              <p>
                <b>File Name:</b> {state.fileName}
              </p>
            </div>
          )}
          <div className="download_xlsx">
            <a href="/sample.xlsx" download>
              <Button className="yellowbtn">Download Sample File</Button>
            </a>
          </div>
          <div className="upload_css_btn">
            <button className="bluebtn" onClick={handleSubmit}>
              Upload
            </button>
          </div>
          {/* {state.errorContent && (
            <div className="error-download">
              <Button onClick={() => downloadErrorFile(state.errorContent)}>Download Error Log</Button>
              <div>
                <pre>{JSON.stringify(state.errorContent, null, 2)}</pre>
              </div>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default DeleteModal;
