import React, { Fragment, useEffect, useState } from "react";

import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNav from "../components/Navbars/AdminNavbar";
import AccountHeader from "../components/Account/AccountHeader";
import { fetchaccountDetail } from "../redux/Slices/getAccoutDetails";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Form, Upload } from "antd";
import { UpdateCompanyLogo } from "../redux/postReducer/postUpdateCompanyLogo";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { HiOutlineUpload } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";

export default function AddChecklist() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const { data: accountDetail } = useSelector((state) => state.accountDetail);
  const { loading, message } = useSelector(
    (state) => state.postUpdateCompanyLogo
  );
  useEffect(() => {
    dispatch(fetchaccountDetail());
  }, [dispatch]);

  useEffect(() => {
    if (accountDetail?.image) {
      setFileList([
        {
          uid: "-1",
          name: "profileImage.png",
          status: "done",
          url: accountDetail?.image,
        },
      ]);
    }
  }, [accountDetail]);

  const onFinish = async () => {
    const formData = new FormData();
    fileList?.forEach((file) => {
      if (file?.originFileObj) {
        formData.append("image", file?.originFileObj);
      }
    });

    const response = await dispatch(UpdateCompanyLogo(formData));
    if (response?.success) {
      toast.success("Company Logo updated successfully");
      form.resetFields();
      // refetch();
      dispatch(fetchaccountDetail());
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const imgWindow = window.open(src);
    imgWindow.document.write(`<img src="${src}" />`);
  };
  return (
    <Fragment>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content">
          <div className="addcustomers smsHeader accheader">
            <div className="row cslocation">
              <AccountHeader />
            </div>
          </div>

          <div className="smsHeader">
            <div className="rounded bg-white mt-5 mb-5">
              <div className="row cslocation">
                <div className="col-md-3 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                    <div className="d-flex justify-content-between align-items-center mb-3 mmoobbillee">
                      <h4 className="text-right">Profile Settings</h4>
                    </div>
                    <Form form={form} name="profileImage" onFinish={onFinish}>
                      <div className="row">
                        <div className="col-sm-12 changePasswordP">
                          {/* Wrap Upload in Form.Item */}
                          <Form.Item name="upload">
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              beforeUpload={() => false}
                              maxCount={1}
                              onChange={handleChange}
                              onPreview={handlePreview}
                              className="avatar-uploader"
                            >
                              {fileList.length === 0 && (
                                <div>
                                  <HiOutlineUpload />
                                  <div style={{ marginTop: 8 }}>
                                    Upload Image
                                  </div>
                                </div>
                              )}
                            </Upload>
                          </Form.Item>
                        </div>

                        <div className="col-sm-12 changePasswordP uploads">
                          {/* Wrap Button in Form.Item for proper submission */}
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              <FaCheck />
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>

                    {/* <span className="font-weight-bold">{accountDetail?.FirstName + " " + accountDetail?.LastName}</span> */}
                    {/* <span className="text-black-50 fontsize-css">
                      {accountDetail?.Email}
                    </span> */}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="p-3 py-5">
                    <div className="d-flex justify-content-between align-items-center mb-3 ddeskttop">
                      <h4 className="text-right">Profile Settings</h4>
                    </div>
                    <div className="row cslocation mt-3">
                      <div className="col-md-12">
                        <label className="labels">Company Name</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter address line 1"
                          value={accountDetail?.Name}
                        />
                      </div>
                    </div>
                    <div className="row cslocation mt-2">
                      <div className="col-md-6">
                        <label className="labels">First Name</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="first name"
                          value={accountDetail?.FirstName}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Last Name</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          value={accountDetail?.LastName}
                          placeholder="surname"
                        />
                      </div>
                    </div>
                    <div className="row cslocation mt-3">
                      <div className="col-md-12">
                        <label className="labels">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter phone number"
                          value={accountDetail?.Mobile}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Email</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter phone number"
                          value={accountDetail?.Email}
                          disabled
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Address</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter address line 1"
                          value={accountDetail?.Address}
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <label className="labels">Customer Range</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter address line 2"
                          value={
                            accountDetail?.CustomerRange1 +
                            "" +
                            accountDetail?.CustomerRange2
                          }
                        />
                      </div> */}
                      <div className="col-md-12">
                        <label className="labels">Zip Code</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter address line 2"
                          value={accountDetail?.Zip}
                        />
                      </div>
                    </div>
                    <div className="row cslocation mt-3">
                      <div className="col-md-6">
                        <label className="labels">State</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="enter address line 2"
                          value={accountDetail?.StateName}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">City</label>
                        <input
                          type="text"
                          className="form-control form-css-data"
                          placeholder="country"
                          value={accountDetail?.CityName}
                        />
                      </div>
                    </div>
                    {/* <div className="mt-5 text-center"><button className="btn btn-primary profile-button" type="button">Save Profile</button></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
